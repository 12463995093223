export default defineNuxtRouteMiddleware((to, from) => {
  const { data: authData } = useAuth();

  if (authData.value && authData.value.user) {
    const role = authData.value.user.role;

    // Vérifier si l'utilisateur est un modérateur ou un admin
    if (role !== "admin") {
      return navigateTo("/"); // Rediriger vers la page d'accueil si rôle non autorisé
    }
  } else {
    return navigateTo("/"); // Rediriger si non authentifié
  }
});
